import React, { useState } from 'react';
import DashboardIcon from "../components/icons/dashboard";
import ClientsIcon from "../components/icons/clients";
// import FinancialReportIcon from "../components/icons/financialReport";
import UsersIcon from "../components/icons/users";
import TransactionsIcon from "../components/icons/transactions";
// import MapIcon from '../components/icons/maps';

import { useTheme } from '@mui/joy';

const UIContext = React.createContext();

export const UIContextProvider = (props) => {
    const theme = useTheme();

    const [menu, setMenu] = useState([
        { icon: <DashboardIcon theme={theme} />, name: 'Operador', state: true, route: "/dashboard" },
        // { icon: <DashboardIcon theme={theme} />, name: 'Operador', state: false, route: "/dashboard_operator" },
        { icon: <ClientsIcon theme={theme} />, name: 'Clientes', state: false, route: "/clients" },
        { icon: <ClientsIcon theme={theme} />, name: 'KYC', state: false, route: "/kyc" },
        { icon: <TransactionsIcon theme={theme} />, name: 'Transacciones', state: false, route: "/transactions" },
        // { icon: <FinancialReportIcon theme={theme} />, name: 'Reporte financiero', state: false, route: "/financial_report" },
        { icon: <UsersIcon theme={theme} />, name: 'Usuarios', state: false, route: "/users" },
        // { icon: <MapIcon theme={theme} />, name: 'Mapas', state: false, route: "/maps" },
    ]);

    const [menuOperator, setMenuOperator] = useState([
        { icon: <DashboardIcon theme={theme} />, name: 'Inicio', state: true, route: "/dashboard_operator" },
        { icon: <ClientsIcon theme={theme} />, name: 'Clientes', state: false, route: "/clients" },
        { icon: <TransactionsIcon theme={theme} />, name: 'Transacciones', state: false, route: "/transactions" },
    ]);

    const setActiveMenuItem = (index) => {
        const updatedMenu = menu.map((item, i) => {

            item.state = index === i ? true : false;

            return {
                ...item,
            };
        });
        setMenu(updatedMenu);
    };

    const setActiveMenuOperatorItem = (index) => {
        const updatedMenu = menuOperator.map((item, i) => {

            item.state = index === i ? true : false;

            return {
                ...item,
            };
        });
        setMenuOperator(updatedMenu);
    };    

    return (
        <UIContext.Provider
            value={{
                menu,
                setActiveMenuItem,
                menuOperator,
                setActiveMenuOperatorItem
            }}
        >
            {props.children}
        </UIContext.Provider>
    );
};

export default UIContext;


