import { Box, Grid, Input, Typography, useTheme } from "@mui/joy";
import Snackbar from "@mui/joy/Snackbar";
import CustomInput from "../UI/custom_elements/customInput";
import PrimaryButton from "../UI/custom_elements/primaryButton";
import SecondaryButton from "../UI/custom_elements/secondaryButton";
import { useContext, useState } from "react";
import SessionContext from "../../store";
import moment from "moment";
import usePut from "../../hooks/usePut";

const EditClient = ({ editClient, type, close, fetchData }) => {
  const theme = useTheme();
  const [client, setClient] = useState(editClient);

  const [errorMessage, setErrorMessage] = useState(false);
  const [infoMessage, setInfoMessage] = useState(false);

  const { session } = useContext(SessionContext);

  const { put, isLoading: editLoading } = usePut();

  const save = async () => {
    const formattedBirthday = new Date(client.birthday).toISOString().split('T')[0];
    
    let body = {
        user_id: client._id,
        first_name: client.first_name,
        middle_name: client.middle_name,
        father_last_name: client.father_last_name,
        mother_last_name: client.mother_last_name,
        birthday: formattedBirthday,
    };
    try {
      const response = await put(
        process.env.REACT_APP_URL_API + "/admin/clients/review/kyc",
        body,
        session.token
      );
      if (response) {
        fetchData();
        close();
      } else {
        setErrorMessage(true);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(true);
    }
  };

  const formatDate = (isoDate) => {
    return moment(isoDate).format("YYYY-MM-DD");
  };

  const desactived = () => {
    if (
      client.first_name === "" ||
      client.father_last_name === "" ||
      client.mother_last_name === "" ||
      client.birthday === ""
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Snackbar
        open={errorMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        variant="solid"
        color="danger"
        autoHideDuration={4000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setErrorMessage(false);
        }}
      >
        Ocurrió un problema, por favor valide que su información sea valida.
      </Snackbar>
      <Snackbar
        open={infoMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        variant="solid"
        color="neutral"
        autoHideDuration={4000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setInfoMessage(false);
        }}
      >
        Complete la información antes de continuar / cambie al menos un campo
      </Snackbar>
      <Box height={500} width={700}>
        <Box
          sx={{ overflowY: "auto", maxHeight: "500px", overflowX: "hidden" }}
        >
          <Grid container columns={2} spacing={2} sx={{ flexGrow: 1 }}>
            <Grid md={1}>
              <Typography
                mb={0.5}
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "150%",
                  color: theme.vars.palette.text.main,
                }}
              >
                {"Nombre (s)"}
              </Typography>
              <CustomInput
                placeholder="Nombre"
                width="100%"
                disabled={type === 'BIRTH_DATE_MISMATCH' ? true : false}
                setValue={(value) =>
                  setClient((prevUser) => ({
                    ...prevUser,
                    first_name: value,
                  }))
                }
                value={client.first_name ? client.first_name : ""}
              />
            </Grid>
            <Grid md={1}>
              <Typography
                mb={0.5}
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "150%",
                  color: theme.vars.palette.text.main,
                }}
              >
                {"Segundo nombre"}
              </Typography>
              <CustomInput
                placeholder="Segundo nombre"
                disabled={type === 'BIRTH_DATE_MISMATCH' ? true : false}
                width="100%"
                setValue={(value) =>
                  setClient((prevUser) => ({
                    ...prevUser,
                    middle_name: value,
                  }))
                }
                value={client.middle_name ? client.middle_name : ""}
              />
            </Grid>
            <Grid md={1}>
              <Typography
                mb={0.5}
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "150%",
                  color: theme.vars.palette.text.main,
                }}
              >
                {"Apellido paterno"}
              </Typography>
              <CustomInput
                placeholder="Apellido paterno"
                width="100%"
                disabled={type === 'BIRTH_DATE_MISMATCH' ? true : false}
                setValue={(value) =>
                  setClient((prevUser) => ({
                    ...prevUser,
                    father_last_name: value,
                  }))
                }
                value={client.father_last_name ? client.father_last_name : ""}
              />
            </Grid>
            <Grid md={1}>
              <Typography
                mb={0.5}
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "150%",
                  color: theme.vars.palette.text.main,
                }}
              >
                {"Apellido materno"}
              </Typography>
              <CustomInput
                placeholder="Apellido materno"
                width="100%"
                disabled={type === 'BIRTH_DATE_MISMATCH' ? true : false}
                setValue={(value) =>
                  setClient((prevUser) => ({
                    ...prevUser,
                    mother_last_name: value,
                  }))
                }
                value={client.mother_last_name ? client.mother_last_name : ""}
              />
            </Grid>
            <Grid md={1}>
              <Typography
                mb={0.5}
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "150%",
                  color: theme.vars.palette.text.main,
                }}
              >
                {"Fecha de nacimiento"}
              </Typography>
              <Input
                type="date"
                disabled={type === 'FULL_NAME_MISMATCH' ? true : false}
                onChange={(e) =>
                  setClient((prevUser) => ({
                    ...prevUser,
                    birthday: e.target.value,
                  }))
                }
                value={formatDate(client.birthday)}
                sx={{
                  display: "flex",
                  padding: "8px 16px",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  color: theme.vars.palette.text.main,
                  gap: "8px",
                  borderRadius: "12px",
                  border: "2px solid #18E9E9",
                  backgroundColor: "transparent",
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "120%",
                }}
              />
            </Grid>
          </Grid>
          <Grid mt={2} container columns={2} spacing={2} sx={{ flexGrow: 1 }}>
            <Grid md={1}>
              <SecondaryButton
                click={() => close()}
                text="Cancelar"
                full={true}
              />
            </Grid>
            <Grid md={1}>
              <PrimaryButton
                desactived={desactived()}
                loading={editLoading}
                click={() => {
                  desactived() === true ? setInfoMessage(true) : save();
                }}
                text="Guardar"
                full={true}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default EditClient;
