import { Box, Grid, Typography, useTheme } from "@mui/joy";

import { useContext, useEffect, useState } from "react";
import useGet from "../../hooks/useGet";
import SessionContext from "../../store";
import CustomInput from "../../components/UI/custom_elements/customInput";
import PrimaryButton from "../../components/UI/custom_elements/primaryButton";
import { Loading2 } from "../../components/UI/custom_elements/loading2";
import HeadTable from "../../components/UI/custom_elements/headTable";
import TableClients from "../../components/kyc/tableClients";

const Kyc = () =>{

    const theme = useTheme();

    const heads = ["Usuario", "", "Fecha de nacimiento", "Review type", "Aiprise id", "Status"];

    const [filter, setFilter] = useState({
        term: ""
    });

    const {error, isLoading, get} = useGet();

    const { session } = useContext(SessionContext);

    const [clients, setClients] = useState([]);

    if(error){
        console.log(error);
    }

    const fetchData = async () => {
        try {
            if(session.token){
                let queryString = "/admin/clients/review/kyc";
                
                if (filter.term !== "") {
                    queryString += `?term=${filter.term}`;
                }
    
                const result = await get(queryString, session.token);
                if(result){
                    setClients(result);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const search = () => {
        fetchData();
    }

    useEffect(()=>{
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])   

    return(
        <>
            <Box mx={5} my={2} sx={{width: "calc(100vw - 232px)"}}>
                <Box>
                    <Typography sx={{
                        fontFamily: "Montserrat",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "120%",
                        color: theme.vars.palette.primary["500"],
                    }}>
                        Clientes - Review KYC
                    </Typography>
                </Box>
                <Box display="flex" mt={2}>
                    <CustomInput 
                        placeholder="Busqueda General" 
                        width="100%" 
                        setValue={(value) =>   
                            setFilter(prevUser => ({
                                ...prevUser,
                                term: value 
                            })
                        )} 
                        value={filter.term}
                    />
                    <Box ml={2}>
                        <PrimaryButton click={()=> search()} text="Buscar" add={true} />
                    </Box>
                </Box>
                <Box mt={4}>
                    <Grid container spacing={2} columns={12} display="flex" sx={{flexGrow: 1}}>
                        <Grid display="flex" md={11}>
                            <HeadTable heads={heads} theme={theme} />
                        </Grid>
                        <Grid md={1}></Grid>
                    </Grid>
                </Box>
                <Box mt={4}>
                    {
                        isLoading ? (
                            <Loading2 />
                        ) : (
                            // <ClientCards elements={clients} theme={theme} fetchData={fetchData} />
                            <TableClients elements={clients} theme={theme} columns={heads.length} fetchData={fetchData} />
                        )
                    }
                </Box>
            </Box>
        </>
    )
}

export default Kyc;