import { Box, Grid, Typography } from "@mui/joy";
import Dialog from "../UI/custom_elements/dialog";
import { useContext, useState } from "react";
import EditClient from "./editClient";
import moment from 'moment';
import useGet from "../../hooks/useGet";
import SessionContext from "../../store";
import { Loading2 } from "../UI/custom_elements/loading2";

const TableClients = ({ elements, theme, columns, fetchData }) => {
    const [activeMenu, setActiveMenu] = useState(false);
    const [indexS, setIndex] = useState(null);
    const [editClient, setEditClient] = useState(false);
    const [editClientData, setEditClientData] = useState(false);

    const [client, setClient] = useState({});

    const { isLoading, get} = useGet();
    const { session } = useContext(SessionContext);

    const convertDateFormat = (isoDate) => {
        const momentDate = moment(isoDate);
        return momentDate.format('DD/MM/YYYY');
    }

    const getClient = async () => {
        try {
            setEditClient(true);
            const result = await get("/admin/clients/" + client._id, session.token);
            if(result){
                setEditClientData(result);
            }
        } catch (error) {
            console.log(error);
        }

    }

    return (
        <Box onClick={() => {setIndex(null) }}>
            <Dialog title={"Validar cliente - " + client.aiprise_review_type} open={editClient} close={()=> setEditClient(false)}>
                {
                    isLoading ? (
                        <Box height={500} width={700}>
                            <Box sx={{overflowY: "auto", maxHeight: "500px"}}>
                                <Loading2 />
                            </Box> 
                        </Box>
                    ) : (
                        <EditClient editClient={editClientData} type={client.aiprise_review_type} close={()=> setEditClient(false)} fetchData={fetchData} />
                    )
                }
            </Dialog>
            {
                elements.map((element, index) => {
                    return (
                        <Grid container spacing={1} columns={12} display="flex" key={index} sx={{ flexGrow: 1 }}>
                            <Grid display="flex" justifyContent="center" alignItems="center" md={11}>
                                <Grid
                                    container
                                    spacing={0}
                                    mb={2}
                                    columns={columns}
                                    sx={{
                                        flexGrow: 1,
                                        borderRadius: "12px",
                                        padding: "8px",
                                    }}>
                                    <Grid md={1}>
                                        <Box display="flex" justifyContent="start" alignItems="center">
                                            <Box maxWidth={180}>
                                                <Typography sx={{
                                                    fontFamily: "Montserrat",
                                                    fontSize: "14px",
                                                    fontStyle: "normal",
                                                    fontWeight: 400,
                                                    lineHeight: "150%",
                                                    color: theme.vars.palette.text.main,
                                                    whiteSpace: "nowra",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis"
                                                }}>
                                                    {element.first_name + " " + (element.middle_name ? element.middle_name + " " : "") + element.father_last_name + " " + element.mother_last_name}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                        <Typography sx={{
                                            fontFamily: "Montserrat",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "150%",
                                            color: theme.vars.palette.text.main,
                                            whiteSpace: "nowra",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis"
                                        }}>
                                            {element.country_code} {element.mobile}
                                        </Typography>
                                    </Grid>
                                    <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                        <Typography sx={{
                                            fontFamily: "Montserrat",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "150%",
                                            color: theme.vars.palette.text.main,
                                        }}>
                                            {element.birthday ? convertDateFormat(element.birthday) : "-"}
                                        </Typography>
                                    </Grid>
                                    <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                        <Typography sx={{
                                            fontFamily: "Montserrat",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "150%",
                                            color: theme.vars.palette.text.main,
                                            whiteSpace: "nowra",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis"
                                        }}>
                                            {element.aiprise_review_type ? element.aiprise_review_type : '-'}
                                        </Typography>
                                    </Grid>
                                    <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                        <Typography sx={{
                                            fontFamily: "Montserrat",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "150%",
                                            color: theme.vars.palette.text.main,
                                            whiteSpace: "nowra",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis"
                                        }}>
                                            {element.aiprise_verification_session_id ? element.aiprise_verification_session_id : '-'}
                                        </Typography>
                                    </Grid>
                                    <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                        <Box sx={{
                                            borderRadius: "12px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: 120,
                                            py: 0.5,
                                            backgroundColor: theme.vars.palette.card,
                                            height: "24px",
                                        }}
                                        >
                                            <Box display="flex" alignItems="center" height={32}>
                                                {
                                                    element.status === "active" && (
                                                        <Box display="flex">
                                                            <Box>
                                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="6" cy="6" r="6" fill="#1AF565" />
                                                                </svg>
                                                            </Box>
                                                            <Box ml={1}>
                                                                <Typography sx={{
                                                                    fontFamily: "Montserrat",
                                                                    fontSize: "12px",
                                                                    fontStyle: "normal",
                                                                    fontWeight: 400,
                                                                    lineHeight: "150%",
                                                                    color: theme.vars.palette.text.main,
                                                                }}>
                                                                    Activo
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    )
                                                }
                                                {
                                                    element.status === "suspended" && (
                                                        <Box display="flex">
                                                            <Box>
                                                                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="6.66699" cy="6" r="6" fill="#B1993A" />
                                                                </svg>
                                                            </Box>
                                                            <Box ml={1}>
                                                                <Typography sx={{
                                                                    fontFamily: "Montserrat",
                                                                    fontSize: "12px",
                                                                    fontStyle: "normal",
                                                                    fontWeight: 400,
                                                                    lineHeight: "150%",
                                                                    color: theme.vars.palette.text.main,
                                                                }}>
                                                                    Suspendido
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    )
                                                }
                                                {
                                                    (element.status === "blocked" || element.status === "temporary-blocked") && (
                                                        <Box display="flex">
                                                            <Box>
                                                                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="6.33301" cy="6" r="6" fill="#F20E25" />
                                                                </svg>
                                                            </Box>
                                                            <Box ml={1}>
                                                                <Typography sx={{
                                                                    fontFamily: "Montserrat",
                                                                    fontSize: "12px",
                                                                    fontStyle: "normal",
                                                                    fontWeight: 400,
                                                                    lineHeight: "150%",
                                                                    color: theme.vars.palette.text.main,
                                                                }}>
                                                                    Bloqueado
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    )
                                                }
                                                {
                                                    element.status !== "blocked" && element.status !== "suspended" && element.status !== "active" && element.status !== "temporary-blocked" && (
                                                        <Box display="flex">
                                                            <Box>
                                                                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="6.33301" cy="6" r="6" fill="#F20E25" />
                                                                </svg>
                                                            </Box>
                                                            <Box>
                                                                <Typography sx={{
                                                                    fontFamily: "Montserrat",
                                                                    fontSize: "12px",
                                                                    fontStyle: "normal",
                                                                    fontWeight: 400,
                                                                    lineHeight: "150%",
                                                                    color: theme.vars.palette.text.main,
                                                                }}>
                                                                    -
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    )
                                                }
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid display="flex" justifyContent="center" alignItems="center" md={1}>
                                <Box
                                    position="relative"
                                    onClick={(e) => {e.stopPropagation(); setClient(element); setActiveMenu(true); setIndex(index) }}
                                    sx={{
                                        mb: 2.5
                                    }}>
                                    <Box display="flex" justifyContent="center" alignItems="center" md={1} sx={{cursor: "pointer"}}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6 14.6667H10C13.3333 14.6667 14.6667 13.3334 14.6667 10V6.00004C14.6667 2.66671 13.3333 1.33337 10 1.33337H6C2.66667 1.33337 1.33334 2.66671 1.33334 6.00004V10C1.33334 13.3334 2.66667 14.6667 6 14.6667Z" stroke={theme.vars.palette.icon} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10.6643 7.99996H10.6703" stroke={theme.vars.palette.icon} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M7.99699 7.99996H8.00298" stroke={theme.vars.palette.icon} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M5.32967 7.99996H5.33566" stroke={theme.vars.palette.icon} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </Box>
                                    {
                                        activeMenu && indexS === index && (
                                            <Box position="absolute" top="100%" right="0%" sx={{
                                                padding: "12px 8px 12px 8px",
                                                background: theme.vars.palette.card,
                                                borderRadius: "12px",
                                                width: "150px",
                                                zIndex: 1000
                                            }}>
                                                <Box onClick={(event) => {event.stopPropagation(); getClient(); setIndex(null)}} sx={{
                                                    cursor: "pointer",
                                                    "&:hover": {
                                                        backgroundColor: theme.vars.palette.background.body
                                                    },
                                                    borderRadius: "12px",
                                                    px: 1,
                                                    py: 0.5
                                                }}>
                                                    <Typography sx={{
                                                        fontFamily: "Montserrat",
                                                        fontSize: "14px",
                                                        fontStyle: "normal",
                                                        fontWeight: 400,
                                                        lineHeight: "13px",
                                                        color: theme.vars.palette.text.main,
                                                    }}>
                                                        Validar cliente
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        )
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    )
                })
            }
        </Box>
    )
}

export default TableClients;